'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var app = {
	dropdownMenus: function dropdownMenus() {
		// ASSIGN DATA ATTRIBUTES

		$('.parent > a').on('click', function (e) {
			var selected = $(this);
			var subMenu = selected.next('ul');
			var hasSubMenu = subMenu.length == 1 ? true : false;

			if (hasSubMenu == true) {
				e.preventDefault();

				// Show
				if (selected.parent('li').hasClass('selected') == true) {
					// Hide
					selected.parent('li').removeClass('selected');
					$('.parent-sub').hide();
				} else {
					// Show
					selected.addClass('selected');
					$('.parent-sub').hide();
					$('.parent a').removeClass('selected');
					selected.parent('li').addClass("selected");
					subMenu.fadeIn();
				}
			}
		});
	},
	mobileMenu: function mobileMenu() {
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if (mobileBtn.length > 0) {
			// Load Menu
			mobileBtn.unbind().click(function () {
				if (mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function (e) {
				var clicked = e.currentTarget.id.replace("showMenu", "");
				var target = mobileNavItems.find('#subMenu' + clicked);
				var status = target.hasClass("open");

				if (status == false) {
					secondMenu.slideUp(function () {
						secondMenu.removeClass('open');
					});

					target.slideDown(function () {
						target.addClass('open');
					});
				} else {
					target.slideUp(function () {
						target.removeClass('open');
					});
				}
			});
		}

		//	$('#mobile-nav-inner-items').hcSticky({
		//	bottom:0
		//	});
	},

	setImgThumbs: function setImgThumbs() {
		var total = $('#thumbs-product-img-gallery').attr('data-total-images');
		var thumbs = total > 2 ? 3 : 2;
		var width = $('#main-product-img-gallery').width();
		var maxWidth = width / 3 - 13.6;
		$('.thumb-item').css('maxWidth', maxWidth + "px");
	},
	productImages: function productImages() {
		var slideshows = $('.cycle-slideshow').on('cycle-next cycle-prev', function (e, opts) {
			// advance the other slideshow
			slideshows.not(this).cycle('goto', opts.currSlide);
		});

		$('#thumbs-product-img-gallery .cycle-slide').click(function () {
			var index = $('#thumbs-product-img-gallery').data('cycle.API').getSlideIndex(this);
			slideshows.cycle('goto', index);
		});
	},

	validateEmail: function validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	},
	enquiryDialogForm: function enquiryDialogForm() {
		var btn = $('.enquiry_frm_btn');
		btn.click(function () {
			var selected = $(this);
			var attr = selected.attr('data-sub-title');

			var data = {
				title: 'Enquire Now',
				subTitle: '',
				content: $('#enquire-frm').html()
			};

			if ((typeof attr === 'undefined' ? 'undefined' : _typeof(attr)) !== (typeof undefined === 'undefined' ? 'undefined' : _typeof(undefined)) && attr !== false) {
				data.subTitle = selected.attr('data-sub-title');
			}
			if (data.subTitle != '') {
				data.title += " - " + data.subTitle;
			}

			// Open customized confirmation dialog window
			$.fancybox.open({
				type: 'html',
				src: '<div class="fc-content rounded frm-dialog">' + '<div class="top">' + '<h2>' + data.title + '</h2>' + '</div>' + '<div class="dialog-item-inner">' + data.content + '</div>' + '</div>',
				opts: {
					afterShow: function afterShow(instance, current) {
						$('.frm-dialog #submit_enquiry_btn').click(function (e) {
							e.preventDefault();
							var form = $('.dialog-item-inner form');
							var validation = $('.dialog-item-inner #validation');
							var subject = "Enquiry";

							// MODIFY SUBJECT LINE
							if ($('h1#pageTitle').length == 1) {
								subject += " on " + $('h1#pageTitle').html();
							}
							if (data.subTitle != '') {
								subject += ": " + data.subTitle;
							}

							form.find('#referer').val(document.referrer);
							form.find('#subject').val(subject);

							// CHK VALIDATION
							var valid = true;
							var inputs = form.find('input, textarea');

							inputs.removeClass('required');
							$.each(inputs, function (key, row) {
								var row = $(row);
								if (row.attr('required') && row.val() == '') {
									row.addClass('required');
									valid = false;
								}
								if (row.attr('type') == 'email' && row.val() != '') {
									if (app.validateEmail(row.val()) == false) {
										row.addClass('required');
										valid = false;
									}
								}
							});

							var formData = form.serialize();

							if (valid == true) {
								$.ajax({
									type: 'POST',
									url: form.attr('action'),
									data: formData
								}).done(function (response) {
									if (response.success == true) {
										// SUBMISSION
										validation.html('Your enquiry was submitted successfully.');
										validation.removeClass('error');
										form.hide();
									} else {
										// FAIL
										validation.html('Your enquiry could not be sent.  Please try again.');
										validation.addClass('error');
									}
									validation.removeClass('hidden');
								}).fail(function (response) {
									// FAIL
									validation.html('Your enquiry could not be sent.  Please try again.');
									validation.addClass('error').removeClass('hidden');
								});
							}
						});
					}
				}
			});
		});
	},

	dearDiaryFancybox: function dearDiaryFancybox() {
		$('[data-fancybox="gallery"]').fancybox({
			toolbar: true,
			infobar: false,
			arrows: true,
			buttons: ["close"],
			idleTime: false
		});
	},

	timelineFancybox: function timelineFancybox() {
		btn.unbind().click(function (e) {
			e.preventDefault();
			var selected = $(this);
			var target = selected.attr('data-target');
			var prev = $(target + " .arrow-left");
			var nxt = $(target + " .arrow-right");
			var counter = $(target + " .photos-counter span:nth-child(1)");
			var thumbs = $(target + ' .timeline-gallery-thumbs');
			// Open customized confirmation dialog window
			$.fancybox.open({
				type: 'inline',
				src: target,
				opts: {
					afterShow: function afterShow(instance, current) {
						$(target + ' .timeline-gallery').on('init', function (event, slick) {
							$(target + ' .timeline-gallery-wrap').removeClass('zero');
						});
						$(target + ' .timeline-gallery').slick({
							slidesToShow: 1,
							slidesToScroll: 1,
							fade: false,
							arrows: false,
							draggable: false,
							useTransform: false,
							mobileFirst: true
						});
						thumbs.on('init', function (event, slick) {
							counter.text(slick.currentSlide + 1 + "/");
							$(target + " .photos-counter span:nth-child(2)").text(slick.slideCount);
						});
						thumbs.slick({
							slidesToScroll: 1,
							useTransform: false,
							fade: false,
							arrows: true,
							infinite: true,
							prevArrow: prev,
							nextArrow: nxt,
							asNavFor: target + ' .timeline-gallery'
						});
						$(target + ' .slick-slide').on("click", function () {
							var index = $(this).attr("data-slick-index");
							thumbs.slick("slickGoTo", index);
						});

						$(target + ' .timeline-gallery').on("afterChange", function (event, slick, currentSlide) {
							counter.text(slick.currentSlide + 1 + '/');
						});
					}
				}
			});
		});
	},
	initTabs: function initTabs() {
		var tabs = $('#tabs-btns span');
		var tabContent = $('#tabs-content .tab-wrap-content-inner');

		$.each(tabContent, function (key, row) {
			var row = $(row);
			row.attr('data-tab-content', key);
			row.addClass('hidden');
		});

		$.each(tabs, function (key, row) {
			var row = $(row);
			row.attr('data-tab-btn', key);
			row.click(function () {
				var selected = $(this);
				tabs.removeClass('selected');
				selected.addClass('selected');
				tabContent.addClass('hidden');
				$('#tabs-content .tab-wrap-content-inner[data-tab-content=' + key + ']').removeClass('hidden').fadeIn();
			});

			if (key == 0) {
				row.trigger('click');
			}
		});
	},

	filterItems: function filterItems() {
		// init Isotope
		var $grid = $('#isotope').isotope({
			// options
			itemSelector: '.item',
			layoutMode: 'fitRows'
		});

		$('#isotope a.item').each(function (key, row) {
			var row = $(row);
			var types = row.attr('data-types');
			var projects = row.attr('data-projects');
			if (types != '') {
				var class_name = types.split(', ');
				$.each(class_name, function (classId, className) {
					className = $.trim(className);
					row.addClass('types-' + className.replace(/\s+/g, '-').toLowerCase());
				});
			}
			if (projects != '') {
				var class_name = projects.split(', ');
				$.each(class_name, function (classId, className) {
					className = $.trim(className);
					row.addClass('projects-' + className);
				});
			}
		});

		$('#cat_sel').unbind().on('change', function (e) {
			e.preventDefault();
			if ($('#isotope').length == 0) {
				var selected = $(this).find('option:selected')[0].value;
				if ($('#type_sel').length === 1) {
					var type_sel = $('#type_sel').val();
					if (type_sel.search('/?/') > 0) {
						type_sel = type_sel.split('?');
						selected += "?" + type_sel[1];
					}
				}
				window.location = selected;
			} else {
				var selected = $(this).find('option:selected')[0];
				var id = $(selected).attr('data-id');
				// see if type is selected
				var type_sel = $($('#type_sel option:selected')[0]).attr('data-id');
				if (id === undefined) {
					if (type_sel === undefined) {
						$grid.isotope({
							filter: ''
						});
					} else {
						$grid.isotope({
							filter: '.types-' + type_sel
						});
					}
				} else {
					if (type_sel === undefined) {
						$grid.isotope({
							filter: '.projects-' + id
						});
					} else {
						$grid.isotope({
							filter: '.projects-' + id + '.types-' + type_sel
						});
					}
				}
			}
		});
		$('#type_sel').unbind().on('change', function (e) {
			e.preventDefault();
			if ($('#isotope').length == 0) {
				var selected = $(this).find('option:selected')[0];
				var dataId = $(selected).attr('data-id');
				var items = $('.news-overview a.item');
				if (dataId != null && dataId != undefined) {
					items.addClass('hidden');
					var selected = $('.news-overview a.item[data-types*="' + dataId + '"]');
					selected.removeClass('hidden');
				} else {
					items.removeClass('hidden');
				}
			} else {
				var selected = $(this).find('option:selected')[0];
				var id = $(selected).attr('data-id');
				// see if cat is selected
				var cat_sel = $($('#cat_sel option:selected')[0]).attr('data-id');
				if (id === undefined) {
					if (cat_sel === undefined) {
						$grid.isotope({
							filter: ''
						});
					} else {
						$grid.isotope({
							filter: '.projects-' + cat_sel
						});
					}
				} else {
					if (cat_sel === undefined) {
						$grid.isotope({
							filter: '.types-' + id
						});
					} else {
						$grid.isotope({
							filter: '.types-' + id + '.projects-' + cat_sel
						});
					}
				}
			}
		});
		/*
  $('#resource_type_sel').unbind().on('change', function(e) {
  	e.preventDefault();
  	var selected = $(this).find('option:selected')[0];
  	var dataId = $(selected).attr('data-id');
  	var items = $('.news-overview a.item');
  	if((dataId != null) && (dataId != undefined)) {
  		items.addClass('hidden');
  		var selected = $('.news-overview a.item[data-resource_type*="' + dataId + '"]');
  		selected.removeClass('hidden');
  	} else {
  		items.removeClass('hidden');
  	}
  });*/
	},

	timelineStickyDate: function timelineStickyDate() {},

	init: function init() {

		app.dropdownMenus();
		app.mobileMenu();

		if ($('#homepage').length > 0) {
			app.homepage();
		}

		if ($('#tabs').length > 0) {
			app.initTabs();
		}

		if ($('.enquiry_frm_btn').length > 0) {
			app.enquiryDialogForm();
		}

		if ($('.timeline-dialog-trigger').length > 0) {
			app.timelineStickyDate();
		}

		if ($('.timeline-gallery-wrap').length > 0) {
			console.log("loadinggallery");
			app.timelineFancybox();
		}

		if ($('.rotate-items-slick').length > 0) {
			app.cycleItems();
		}

		if ($('#dearDiaryFancybox').length > 0) {
			app.dearDiaryFancybox();
		}
	}
};

$(window).load(function () {
	app.init();
});

$(document).ready(function () {
	if ($('#thumbs-product-img-gallery').length > 0) {
		app.productImages();
		app.setImgThumbs();
	}
});

$(document).ready(function () {
	var $tabs = $('#horizontalTab');
	$tabs.responsiveTabs({
		rotate: false,
		startCollapsed: 'accordion',
		collapsible: 'accordion',
		setHash: true,
		click: function click(e, tab) {
			$('.info').html('Tab <strong>' + tab.id + '</strong> clicked!');
		},
		activate: function activate(e, tab) {
			$('.info').html('Tab <strong>' + tab.id + '</strong> activated!');
		},
		activateState: function activateState(e, state) {
			//console.log(state);
			$('.info').html('Switched from <strong>' + state.oldState + '</strong> state to <strong>' + state.newState + '</strong> state!');
		}
	});
});

$(document).on('ready', function () {
	$(".regular").slick({
		dots: true,
		infinite: true,
		autoplay: false,
		slidesPerRow: 4,
		rows: 2,
		nextArrow: $('.next'),
		prevArrow: $('.prev'),
		appendDots: $('.slickpagination'),
		dotsClass: 'dots',
		responsive: [{
			breakpoint: 1280,
			settings: {
				slidesPerRow: 3,
				rows: 2
			}
		}, {
			breakpoint: 945,
			settings: {
				slidesPerRow: 2,
				rows: 2
			}
		}, {
			breakpoint: 628,
			settings: {
				slidesPerRow: 1,
				rows: 3
			}
		}]
	});
	$(".slideshow").slick({
		dots: true,
		infinite: true,
		fade: true,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: $('.slideshow-next'),
		prevArrow: $('.slideshow-prev'),
		appendDots: $('.slideshow-pagination'),
		customPaging: function customPaging(slideshow, i) {
			var slide = slideshow.$slides[i];
			slide = $(slide).find('.detail').data('title');
			return '<div>' + slide + '</div>';
		},
		dotsClass: 'slick-dots'
	});
	$("#news-carousel").slick({
		dots: true,
		infinite: true,
		autoplay: false,
		slidesPerRow: 3,
		rows: 1,
		nextArrow: $('.news-next'),
		prevArrow: $('.news-prev'),
		appendDots: $('.newspagination'),
		dotsClass: 'dots',
		responsive: [{
			breakpoint: 1000,
			settings: {
				slidesPerRow: 2
			}
		}, {
			breakpoint: 628,
			settings: {
				slidesPerRow: 1
			}
		}]
	});
	$(".event-carousel").slick({
		dots: true,
		infinite: true,
		autoplay: false,
		slidesPerRow: 3,
		rows: 1,
		nextArrow: $('.event-next'),
		prevArrow: $('.event-prev'),
		appendDots: $('.eventpagination'),
		dotsClass: 'dots',
		responsive: [{
			breakpoint: 1000,
			settings: {
				slidesPerRow: 2
			}
		}, {
			breakpoint: 628,
			settings: {
				slidesPerRow: 1
			}
		}]
	});
	$(".related-news-carousel").slick({
		dots: true,
		infinite: true,
		autoplay: false,
		slidesPerRow: 1,
		rows: 1,
		nextArrow: $('.related-news-next'),
		prevArrow: $('.related-news-prev'),
		appendDots: $('.related-news-pagination'),
		dotsClass: 'dots'
	});

	$(".related-event-carousel").slick({
		dots: true,
		infinite: true,
		autoplay: false,
		slidesPerRow: 1,
		rows: 1,
		nextArrow: $('.related-events-next'),
		prevArrow: $('.related-events-prev'),
		appendDots: $('.related-events-pagination'),
		dotsClass: 'dots'
	});

	$.ajax({
		url: '/twitter.json'
	}).done(function (response) {
		var target = $('#tweets');
		var items = response.items;

		$.each(items, function (key, row) {
			var item = '';
			item += '<div class="item">';
			item += '<a href="https://twitter.com/statuses/' + row.id_str + '" class="tweet">';
			item += '<p>';
			item += row.text;
			item += '</p>';
			item += '</a>';
			item += '<div class="tweet_details">';
			item += '<div class="profileimage" style="background-image:url(\'' + row.user.profile_image_url + '\')">';
			item += '<img src="' + row.user.profile_image_url + '" alt="' + row.user.name + '">';
			item += '</div>';
			item += '<h5><a href="https://twitter.com/' + row.user.screen_name + '">twitter.com/' + row.user.screen_name + '</a></h5>';
			item += '</div>';
			item += '</div>';
			target.append(item);
		});

		$(".twitter_feed").slick({
			dots: true,
			infinite: true,
			autoplay: false,
			slidesPerRow: 2,
			rows: 1,
			nextArrow: $('.twitter-next'),
			prevArrow: $('.twitter-prev'),
			appendDots: $('.twitterpagination'),
			dotsClass: 'dots',
			responsive: [{
				breakpoint: 790,
				settings: {
					slidesPerRow: 1
				}
			}]
		});

		app.filterItems();
	});
});

$(document).ready(function () {
	app.init();
	if ($('#thumbs-product-img-gallery').length > 0) {
		app.productImages();
		app.setImgThumbs();
	}
});